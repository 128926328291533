import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Header from '../containers/layout/header';
import Footer from '../containers/layout/footer';
import TrainingsTitle from '../containers/trainings/title';
import TrainingsBanner from '../containers/trainings/banner';
import TrainingsContent from '../containers/trainings/content';
import InstagramWrap from '../containers/global/instagram';

const TrainingsPage = () => (
  <Layout>
    <SEO title="Kursy i szkolenia" />
    <Header />
    <div className="main-content">
      <TrainingsTitle />
      <TrainingsBanner />
      <TrainingsContent />
      <InstagramWrap />
    </div>
    <Footer />
  </Layout>
);

export default TrainingsPage;
