import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from '../../components/shared/heading';
import { TrainingsTitleWrap } from './title.stc';

const TrainingsTitle = ({ titleCSS, taglineCSS }) => {
  const TrainingsTitleData = useStaticQuery(graphql`
    query TrainingsTitleQuery {
      trainingsJson(id: { eq: "trainings-page-header" }) {
        title
        tagline
      }
    }
  `);

  const { title, tagline } = TrainingsTitleData.trainingsJson;

  return (
    <TrainingsTitleWrap>
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xl={{ size: 7, offset: 2 }}>
            {title && <Heading {...titleCSS}>{title}</Heading>}
            {tagline && <Heading {...taglineCSS}>{tagline}</Heading>}
          </Col>
        </Row>
      </Container>
    </TrainingsTitleWrap>
  );
};

TrainingsTitle.defaultProps = {
  titleCSS: {
    as: 'h5',
    fontSize: [1],
    textTransform: 'capitalize',
    mb: '17px'
  },
  taglineCSS: {
    color: 'secondaryColor',
    fontSize: [4, 5],
    fontWeight: 'bold',
    lineHeight: [1.4, 1.375]
  }
};

export default TrainingsTitle;
