import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Heading from '../../components/shared/heading';
import Text from '../../components/shared/text';
import {
  TrainingsContentList,
  TrainingsContentTop,
  TrainingsContentWrap
} from './content.stc';

const TrainingsContent = props => {
  const trainingsData = useStaticQuery(graphql`
    query TrainingsContentQuery {
      trainingsJson(id: { eq: "trainings-page-content" }) {
        top_content {
          content {
            text1
            text2
            text3
          }
        }
        info_left {
          heading
          content {
            text1
            text2
            text3
          }
        }
        list_right {
          heading
          items
        }
      }
    }
  `);

  const { top_content, info_left, list_right } = trainingsData.trainingsJson;

  const { topHeadingCSS, textCSS, listHeadingCSS } = props;

  return (
    <TrainingsContentWrap>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} xl={8}>
            {top_content && (
              <TrainingsContentTop>
                {top_content.heading && (
                  <Heading {...topHeadingCSS}>{top_content.heading}</Heading>
                )}
                {top_content.content.text1 && (
                  <Text {...textCSS}>{top_content.content.text1}</Text>
                )}
                {top_content.content.text2 && (
                  <Text {...textCSS}>{top_content.content.text2}</Text>
                )}
                {top_content.content.text3 && (
                  <Text {...textCSS}>{top_content.content.text3}</Text>
                )}
              </TrainingsContentTop>
            )}
            <Row>
              {false && info_left && (
                <div className="col-md-6">
                  {info_left.heading && (
                    <Heading {...listHeadingCSS}>{info_left.heading}</Heading>
                  )}
                  {info_left.content && (
                    <div>
                      {info_left.content.text1 && (
                        <Text {...textCSS}>{info_left.content.text1}</Text>
                      )}
                      {info_left.content.text2 && (
                        <Text {...textCSS}>{info_left.content.text2}</Text>
                      )}
                      {info_left.content.text3 && (
                        <Text {...textCSS}>{info_left.content.text3}</Text>
                      )}
                    </div>
                  )}
                </div>
              )}
              {list_right && (
                // <Col lg={6}>
                <Col>
                  {list_right.heading && (
                    <Heading {...listHeadingCSS}>{list_right.heading}</Heading>
                  )}
                  {list_right.items && (
                    <TrainingsContentList>
                      {list_right.items.map((item, i) => (
                        <li key={`right-list-${i}`}>{item}</li>
                      ))}
                    </TrainingsContentList>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </TrainingsContentWrap>
  );
};

TrainingsContent.defaultProps = {
  topHeadingCSS: {
    as: 'h4',
    color: 'secondaryColor',
    lineHeight: 1.375,
    mb: '17px'
  },
  listHeadingCSS: {
    as: 'h5',
    mb: '14px'
  },
  textCSS: {
    mb: '26px'
  },
  bottomHeadingCSS: {
    as: 'h5',
    mb: '24px'
  }
};

export default TrainingsContent;
