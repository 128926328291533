import styled from 'styled-components';
import { device } from '../../theme';

export const TrainingsContentWrap = styled.section`
  padding-bottom: 56px;
  ${device.small} {
    padding-bottom: 76px;
  }
  ${device.medium} {
    padding-bottom: 96px;
  }
`;

export const TrainingsContentTop = styled.div`
  margin-bottom: 38px;
`;

export const TrainingsContentList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 11px;
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 7px;
      width: 6px;
      height: 6px;
      background: #99999c;
      border-radius: 50%;
    }
  }
`;
