import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../../components/image';
import { TrainingsBannerWrap } from './banner.stc';

const TrainingsBanner = () => {
  const trainingsBannerData = useStaticQuery(graphql`
    query TrainingsBannerQuery {
      trainingsJson(id: { eq: "trainings-page-banner" }) {
        banner {
          childImageSharp {
            fluid(
              maxWidth: 1260
              maxHeight: 585
              quality: 100
              srcSetBreakpoints: 6
            ) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);
  const bannerImg =
    trainingsBannerData.trainingsJson.banner.childImageSharp.fluid;

  return (
    <TrainingsBannerWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Image fluid={bannerImg} alt="Kursy i szkolenia" />
          </Col>
        </Row>
      </Container>
    </TrainingsBannerWrap>
  );
};

export default TrainingsBanner;
